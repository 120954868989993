export const homeObjOneCon = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'WANT TO GET IN CONTACT? BELOW ARE OUR DETAILS.',
    headline: 'CONTACT INFORMATION',
    description:
      'We provide local shipping. If there are any questions feel free to get in contact with us.',
    buttonLabel: 'Shop Now',
    imgStart: '',
    img: 'images/logo3.jpg',
    alt: 'Credit Card',
    email: 'Bmedirite@gmail.com',
    contactName: 'Bongani Lawrence',
    contactNumber: '083 938 2064',
    Addressln1: '46 van Riebeek Street, ',
    Addressln2: 'Edenvale',
    Addressln3: '',
  };